@import "src/helpers/ScreenSizes.scss";

.completedInvestment {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.completedInvestment:hover > .backgroundImage {
  opacity: 1;
}

.backgroundImage {
  width:100%;
  height: 250px;
  object-fit: cover;
  opacity: 0.3;
  transition: 0.6s;

  @include forTabletOnly() {
    height: 230px;
  }

  @include forPhoneOnly() {
    height: 200px;
  }
}

.shortDescription {
  color: black;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  z-index: 1;
  letter-spacing: 1px;
  font-size: 20px;

  @include forTabletOnly() {
    height: 17px;
  }

  @include forPhoneOnly() {
    margin: 0 40px 0 40px;
    font-size: 15px;
  }
}