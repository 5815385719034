@import "src/helpers/ScreenSizes.scss";

.homeLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeImg {
  background-image: url('../../../img/homeImg.png');
  background-position: center; 
  background-size: cover;
  width: 100%;
  height: calc(80vh);
}

.homeLogo {
  background-image: url('../../../img/homeLogo.png');
  position: absolute;
  background-size: cover;
  transition: 0.5s;

  width: 300px;
  height: 300px;
  @include forTabletOnly() {
    width: 250px;
    height: 250px;
  }

  @include forPhoneOnly() {
    width: 200px;
    height: 200px;
  }
}

.facebookLogo {
  background-image: url('../../../img/facebookLogo.png');
}

.socialNetworks {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
}

.facebookLogo {
  margin: 50px 0px 0px 0px;
  width: 40px;

  @include forTabletOnly() {
    margin: 40px 0px 0px 0px;
    width: 35px;
  }

  @include forPhoneOnly() {
    margin: 25px 0px 0px 0px;
    width: 30px;
  }
}

.instagramLogo {
  margin: 50px 40px 0px 40px;
  width: 40px;

  @include forTabletOnly() {
    margin: 40px 35px 0px 30px;
    width: 35px;
  }

  @include forPhoneOnly() {
    margin: 25px 30px 0px 25px;
    width: 30px;
  }
}