@import "src/helpers/ScreenSizes.scss";

.footer {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100px;

  font-size: 15px;
  letter-spacing: 2px;
  
  margin: 0 40px 0 40px;
  @include forMonitorOnly() {
    font-size: 14px;
  }
  @include forTabletOnly() {
    font-size: 13px;
  }
  @include forPhoneOnly() {
    margin: 0 20px 0 20px;
    font-size: 10px;
  }
}