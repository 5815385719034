@import "src/helpers/ScreenSizes.scss";

.header {
  position: fixed;
  top: 0; 
  width: 100%;
  color: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  z-index: 1000;
}

.headerContainer {
  width: 100%;
}

.headerTooglerIcon {
  width: 30px;
  height: 30px;
  fill: white;
}

@mixin textLogoImgBase {
  max-width: 245px;
  transition: 0.3s;

  @include forTabletOnly() {
    max-width: 190px;
  }
  @include forPhoneOnly() {
    max-width: 180px;
  }
}

.textLogoImg {
  @include textLogoImgBase();
  margin: 17px 0 17px 0;
  @include forTabletOnly() {
    margin: 16px 0 16px 0;
  }
  @include forPhoneOnly() {
    margin: 14px 0 14px 0;
  }
}

.textLogoImgSmall {
  @include textLogoImgBase();
  margin: 13px 0 13px 0;
  @include forTabletOnly() {
    margin: 12px 0 12px 0;
  }
  @include forPhoneOnly() {
    margin: 10px 0 10px 0;
  }
}

@mixin navbarItem {
  text-transform: uppercase;
  cursor: pointer;
  font-size: 18px;
  font-weight: lighter;
  transition: 0.5s;
  @include forMonitorOnly() {
    font-size: 16px;
    margin: 0 17px 0 0;
  }
  @include forTabletOnly() {
    text-align: right;
    font-size: 18px;
    margin: 5px 15px 0 0;
  }
  @include forPhoneOnly() {
    margin: 5px 0 0 0;
    font-size: 17px;
  }

  &:link {
    text-decoration: none;
    color: white;
  }
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
    font-weight: normal;
    transition: 0s;
  }
  &:active {
    color: rgb(190, 190, 190);
    font-weight: normal;
  }
}

.interiorDesign {
  @include navbarItem();
  width: 205px;
  @include forMonitorOnly() {
    width: 170px;
  }
  @include forTabletOnly() {
    text-align: left;
    width: 250px;
  }
}

.contemporaryArchitecture {
  @include navbarItem();
  width: 250px;
  @include forMonitorOnly() {
    width: 210px;
  }
  @include forTabletOnly() {
    text-align: left;
    width: 250px;
  }
}

.atelier {
  @include navbarItem();
  width: 80px;
  @include forMonitorOnly() {
    width: 50px;
  }
  @include forTabletOnly() {
    text-align: left;
    width: 250px;
  }
}

.contact {
  @include navbarItem();
 
  width: 90px;
  @include forMonitorOnly() {
    width: 65px;
  }
  @include forTabletOnly() {
    text-align: left;
    width: 250px;
  }
}
