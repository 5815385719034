@import "src/helpers/ScreenSizes.scss";

@mixin arrowBase {
  width: 60px;
  @include forMonitorOnly() {
    width: 50px;
  }
  @include forPhoneOnly() {
    width: 60px;
  }
}

.leftArrowImg {
  @include arrowBase();
  @include forPhoneOnly() {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.rightArrowImg {
  @include arrowBase();
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);

  @include forPhoneOnly() {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.infoImage {
  width: 220px;
}

.whatDoWeDoGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0px;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: center;
  transition: 0.5s;
  font-weight: lighter;
  text-transform: uppercase;
  margin: 120px 180px 170px 180px;
  @include forMonitorOnly() {
    margin: 100px 50px 150px 50px;
  }
  @include forTabletOnly() {
    margin: 90px 15px 140px 15px;
  }
}

.cellContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%
}

.interiorDesignPartOne {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  margin: 0px 0 50px 0;
  @include forPhoneOnly() {
    margin: 0;
    grid-column: 6;
    grid-row: 4;
  }
}

.interiorDesignImage {
  grid-column: 1;
  grid-row: 2;
  @include forPhoneOnly() {
    grid-column: 6;
    grid-row: 3;
    margin: 50px 0 50px 0;
  }
}

.interiorDesignPartTwo {
  grid-column: 1;
  grid-row: 3;
  align-self: center;
  margin: 50px 0 0 0;
  @include forPhoneOnly() {
    grid-column: 6;
    grid-row: 5;
    align-self: unset;
    margin: 50px 0 0 0;
  }
}

.leftArrow {
  grid-column: 2/5;
  grid-row: 2;
  align-self: center;
  @include forPhoneOnly() {
    grid-column: 6;
    grid-row: 2;
    align-self:unset;
    margin: 50px 0 0 0;
  }
}

.whatDoWeDo {
  grid-column: 5/9;
  grid-row: 2;
  align-self: center;
  font-size: 18px;
  font-weight: normal;

  @include forPhoneOnly() {
    grid-column: 6;
    grid-row: 1;
    align-self:unset;
  }
}

.rightArrow {
  grid-column: 9/12;
  grid-row: 2;
  align-self: center;
  @include forPhoneOnly() {
    grid-column: 6;
    grid-row: 6;
    align-self: unset;
    margin: 50px 0 0 0;
  }
}

.contemporaryArchitecturePartOne {
  grid-column: 12;
  grid-row: 1;
  align-self: center;
  margin: 0px 0 50px 0;
  @include forPhoneOnly() {
    margin: 0;
    grid-column: 6;
    grid-row: 8;
    align-self: unset;
  }
}

.contemporaryArchitectureImage {
  grid-column: 12;
  grid-row: 2;
  @include forPhoneOnly() {
    grid-column: 6;
    grid-row: 7;
    margin: 50px 0 50px 0;
  }
}

.contemporaryArchitecturePartTwo {
  grid-column: 12;
  grid-row: 3;
  align-self: center;
  margin: 50px 0 0 0;
  @include forPhoneOnly() {
    grid-column: 6;
    grid-row: 9;
    align-self: unset;
    margin: 50px 0 0 0;
  }
}