@import "src/helpers/ScreenSizes.scss";

.main {
  margin: 100px 0 0 0;
  transition: 0.2s;
  @include forMonitorOnly() {
    margin: 97px 0 0 0;
  }
  @include forTabletOnly() {
    margin: 85px 0 0 0;
  }
  @include forPhoneOnly() {
    margin: 77px 0 0 0;
  }
}