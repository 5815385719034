@import "src/helpers/ScreenSizes.scss";

.atelierGrid {
  display: grid;
  grid-template-columns: repeat(9 , 2fr);
  grid-gap: 0px;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: center;
  transition: 0.5s;
  text-transform: uppercase;

  font-size: 16px;
  margin: 90px 90px 90px 90px;
  @include forMonitorOnly() {
    margin: 70px 70px 70px 70px;
  }
  @include forTabletOnly() {
    margin: 50px 50px 50px 50px;
    grid-template-columns: repeat(1, 2fr);
    font-size: 13px;
  }

  @include forPhoneOnly() {
    margin: 20px 20px 20px 20px;
    font-size: 9px;
  }
}

.leftSide {
  grid-column: 1/5;
  grid-row: 0;

  @include forTabletOnly() {
    grid-column: 1;
    grid-row: 1;
  }
}

.rightSide {
  grid-column: 6/10;
  grid-row: 1;

  @include forTabletOnly() {
    grid-column: 1;
    grid-row: 2;
  }
}

@mixin sectionTitle {
  font-weight: 600;
}

.atelierTitle {
  @include sectionTitle();
  margin: 0 0 20px 0;
}

.offerTitle {
  @include sectionTitle();
  margin: 50px 0 20px 0;
  @include forPhoneOnly() {
    margin: 30px 0 20px 0;
  }
}

.offerDescriptionTitle {
  margin: 0 0 15px 0;
  text-decoration: underline;
  @include forPhoneOnly() {
    margin: 0 0 10px 0;
  }
}

.descriptionSection {
  margin: 0 0 15px 0;
  @include forPhoneOnly() {
    margin: 0 0 10px 0;
  }
}

.leadingArchitect {
  @include sectionTitle();
  margin: 0 0 20px 0;
  @include forPhoneOnly() {
    margin: 0 0 15px 0;
  }
}

.leadingArchitectProfessionalTitle {
  font-size: 14px;
  @include forPhoneOnly() {
    font-size: 8px;
  }
}

.education {
  @include sectionTitle();
  margin: 20px 0 20px 0;
  @include forPhoneOnly() {
    margin: 15px 0 15px 0;
  }
}

.achievements {
  @include sectionTitle();
  margin: 20px 0 20px 0;
  @include forPhoneOnly() {
    margin: 15px 0 15px 0;
  }
}

.realizations {
  @include sectionTitle();
  margin: 50px 0 20px 0;
  @include forPhoneOnly() {
    margin: 30px 0 15px 0;
  }
}

.inCoopWith {
  margin: 30px 0 15px 0;
  @include forPhoneOnly() {
    margin: 25px 0 15px 0;
  }
}
