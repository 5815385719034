@import "src/helpers/ScreenSizes.scss";

.contemporaryArchitecture {
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-content: center;
  transition: 0.5s;
  width: 100%
}

.contemporaryArchitectureContent {
  width: 100%;

  margin: 0 60px 100px 60px;
  @include forMonitorOnly() {
    margin: 00px 55px 90px 55px;
  }
  @include forTabletOnly() {
    margin: 0 40px 70px 40px;
  }
  @include forPhoneOnly() {
    margin: 0 18px 50px 18px;
  }
}

.title {
  font-size: 27px;
  letter-spacing: 2px;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;

  @include forMonitorOnly() {
    font-size: 25px;
  }
  @include forTabletOnly() {
    font-size: 22px;
  }
  @include forPhoneOnly() {
    font-size: 18px;
  }
}

.completedInvestmentItem {
  margin: 30px 0 0 0;

  @include forTabletOnly() {
    margin: 25px 0 0 0;
  }
  @include forPhoneOnly() {
    margin: 20px 0 0 0;
  }
}

.seeMoreButtonContainer {
  display: flex;
  justify-content: center;

  margin: 10px 0 0 0;
}