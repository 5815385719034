@import "src/helpers/ScreenSizes.scss";

.contact {
  margin: 180px 0 150px 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include forMonitorOnly() {
    margin: 170px 0 140px 0;
  }
  @include forTabletOnly() {
    margin: 120px 0 100px 0;
  }
  @include forPhoneOnly() {
    margin: 70px 0 60px 0;
  }
}

.contactTitle {
  font-size: 30px;
  margin: 0 0 120px 0;

  @include forMonitorOnly() {
    font-size: 28px;
    margin: 0 0 110px 0;
  }
  @include forTabletOnly() {
    font-size: 25px;
    margin: 0 0 80px 0;
  }
  @include forPhoneOnly() {
    font-size: 21px;
    margin: 0 0 30px 0;
  }
}

.contactDescription {
  margin: 0 0 20px 0;
  font-size: 21px;
  @include forMonitorOnly() {
    margin: 0 0 18px 0;
    font-size: 20px;
  }
  @include forTabletOnly() {
    margin: 0 0 16px 0;
    font-size: 17px;
  }
  @include forPhoneOnly() {
    margin: 0 0 14px 0;
    font-size: 12px;
  }
}

@mixin contactContentBase {
  font-size: 19px;
  @include forMonitorOnly() {
    font-size: 18px;
  }
  @include forTabletOnly() {
    font-size: 14px;
  }
  @include forPhoneOnly() {
    font-size: 12px;
  }
}

.link {
  @include contactContentBase();
  color: white;

  &:active {
    color: white;
  }
  &:link {
    text-decoration: none;
    color: white;
  }
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
    font-weight: bolder;
    transition: 0s;
  }
}

.address {
  @include contactContentBase();
}

.lineBreak {
  width: 100%;
}
