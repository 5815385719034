@mixin forPhoneOnly{
  @media (max-width: 767px) { @content; }
}

@mixin forTabletOnly{
  @media (max-width: 992px) { @content; }
}

@mixin forMonitorOnly{
  @media (max-width: 1200px) { @content; }
}
