@import "src/helpers/ScreenSizes.scss";

.seeMoreButton {
  color: black;
  background: none;
  border: none;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 4px;
  font-weight: 200;

  @include forTabletOnly() {
    font-size: 17px;
  }

  @include forPhoneOnly() {
    font-size: 14px;
  }

  &:hover {
    font-weight: 400;
  }
}