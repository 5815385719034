@import "src/helpers/ScreenSizes.scss";

.completedInvestmentDetails{
  display: flex;
  justify-content: center;
}

.carousel {
  margin-top: 10px;
  width: 97%;
  background-color: white;

  @include forPhoneOnly() {
    width: 80%;
  }
}

.firstImage {
  opacity: 0.3;
}


.carouselItemContent {
  display: flex;
  justify-content: center;
  align-items: center;

}

.firstImageDescription {
  position: absolute;
  width: 45%;
  @include forPhoneOnly() {
    width: 70%;
  }
}

.uppercase {
  text-transform: uppercase;
}

.shortDescription {
  color: black;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
  letter-spacing: 3px;
  font-size: 24px;
  font-weight: 600;

  margin: 0 0 20px 0;

  @include forMonitorOnly() {
    font-size: 18px;
    margin: 0 0 15px 0;
  }

  @include forTabletOnly() {
    font-size: 12px;
    margin: 0 0 10px 0;
  }

  @include forPhoneOnly() {
    font-size: 9px;
    margin: 0 0 0 0 ;
  }
}

.longDescription {
  color: black;
  text-align: center;
  z-index: 1;
  font-size: 20px;

  @include forMonitorOnly() {
    font-size: 16px;
  }

  @include forTabletOnly() {
    font-size: 12px;
  }

  @include forPhoneOnly() {
    font-size: 0px;
  }
}

.carouselControlIcon {
  width: 30px !important;
  height: 30px !important;
}
